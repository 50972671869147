import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import Page from '../components/Page';

const AboutPage = ({ data, location }) => {

  const { sanityAboutPage } = data;

  return (
    <Wrapper seo={{seoSettings: sanityAboutPage.seoSettings, title: sanityAboutPage.title }}  location={location}>
      <Page {...data.sanityAboutPage} className="about" />
    </Wrapper>
  );
}

export const AboutPageQuery = graphql`
  query aboutPageQuery {
    sanityAboutPage(_id: {eq: "aboutPage"}) {
      title
      _rawContent
      mainImage {
        alt
        asset {
          fluid(maxWidth: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      accordion {
        id
        title
        _rawContent
        slug {
          current
        }
        label
      }
    }
  }
`;

export default AboutPage;
